<template>
  <current-machine-errors />
</template>

<script>
const CurrentMachineErrors = () => import('@/components/Machines/CurrentMachineErrors')

export default {
  name: 'CurrentMachineErrorsView',
  components: {
    CurrentMachineErrors
  }
}
</script>
